import React from 'react';
import HeroSect from './sections/HeroSect';
import CustomerReviews from './sections/CustomerReviews';
import Dairy from './sections/Dairy';
import Plants from './sections/Plants';
import Benefits from './sections/Benefits';
import AboutUs from './sections/AboutUs';
import Footer from './Footer';
import MangoSection from './sections/MangoSection';

const LandingPage = () => {
    return (
        <div className='mangoLandingPage'>
            <HeroSect />
            <AboutUs />
            <MangoSection />
            <Benefits />
            <CustomerReviews />
            <Dairy />
            <Plants />
            <Footer />
        </div>
    );
};

export default LandingPage;
