import React from 'react';

import '../css/plants.css';

const Plants = () => {
  return (
    <section class="shop_a_plant--wrapper">
      <div class="container">
        <div class="row main_row">
          <div class="col-md-4 col-xl-3 shop_a_plant--col col-12 ">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--vertical">
              <img data-src="/images/shop-plant1.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="363" height="699" src="/images/shop-plant1.png" />
            </div>
          </div>
          <div class="col-xl-6 col-12 shop_a_plant--col">
            <div class="row shop_inner--row">
              <div class="col-md-4 col-xl-3 col-12 shop_a_plant--col">
                <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small">
                  <img data-src="/images/shop-plant2.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant2.png" />
                </div>
              </div>
              <div class="col-md -4 col-xl-6 col-12 shop_a_plant--col">
                <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--mid">
                  <img data-src="/images/shop-plant3.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="362" height="410" src="/images/shop-plant3.png" />
                </div>
              </div>
              <div class="col-md-4 col-xl-3 col-12 shop_a_plant--col">
                <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small">
                  <img data-src="/images/shop-plant4.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant4.png" />
                </div>
              </div>
              <div class="col-md-6 col-xl-9 col-12 shop_a_plant--col">
                <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--long">
                  <div class="shop_plant_img--wrapper">
                    <img data-src="/images/shop-plant2.png" alt="tree" class=" ls-is-cached lazyloaded" width="auto" height="195" src="/images/shop-plant-2.jpg" />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-3 col-12 shop_a_plant--col">
                <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small">
                  <img data-src="/images/shop-plant7.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant7.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-xl-3 col-12 shop_a_plant--col">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--vertical">
              <img data-src="/images/shop-plant5.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="363" height="699" src="/images/shop-plant5.png" />
            </div>
          </div>
          <div class="col-md-4 col-xl-2 col-12 shop_a_plant--col">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small">
              <img data-src="/images/shop-plant8.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant8.png" />
            </div>
          </div>
          <div class="col-md-4 col-xl-3 col-12 shop_a_plant--col">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small">
              <img data-src="/images/shop-plant9.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant9.png" />
            </div>
          </div>
          <div class="col-md-6 col-xl-4 col-12 shop_a_plant--col">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--long shop_plant--long2">
              <div class="shop_plant_img--wrapper">
                <img data-src="/images/shop-plant10.png" alt="tree" class=" ls-is-cached lazyloaded" width="246" height="195" src="/images/shop-plant10.png" />
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-3 col-12 shop_a_plant--col">
            <div onclick="window.location.href='../products/product-details.html';" class="shop_plant--small shop_plant--small2">
              <img data-src="/images/shop-plant11.png" alt="tree" class="plant_img ls-is-cached lazyloaded" width="256" height="410" src="/images/shop-plant11.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Plants;