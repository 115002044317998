import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../api/api';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../product/product.css';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setProductSizes } from "../../../model/reducer/productSizesReducer";
import { setFilterSection } from '../../../model/reducer/productFilterReducer';
import { LuStar } from 'react-icons/lu';

const MangoSection = React.memo(() => {


    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const city = useSelector(state => state.city);
    const shop = useSelector(state => state.shop);
    const setting = useSelector(state => state.setting);
    const cart = useSelector(state => state.cart);

    const sizes = useSelector(state => state.productSizes);
    const favorite = useSelector(state => (state.favourite));
    const [selectedVariant, setSelectedVariant] = useState({});
    const [p_id, setP_id] = useState(0);
    const [p_v_id, setP_V_id] = useState(0);
    const [qnty, setQnty] = useState(0);
    const [productSizes, setproductSizes] = useState(null);
    const [offerConatiner, setOfferContainer] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (sizes.sizes === null || sizes.status === 'loading') {
            if (city.city !== null) {
                api.getProductbyFilter(city.city.id, city.city.latitude, city.city.longitude)
                    .then(response => response.json())
                    .then(result => {
                        if (result.status === 1) {
                            setproductSizes(result.sizes);
                            dispatch(setProductSizes({ data: result.sizes }));
                        }
                    });
            }
        }
        else {
            setproductSizes(sizes.sizes);
        }
    }, [city, sizes]);

    const CustomPrevButton = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} type="button" className="slick-prev">
            <FaChevronLeft fill='black' size={30} className="prev-arrow" />
        </button>
    );
    const CustomNextButton = ({ currentSlide, slideCount, ...props }) => (
        <button {...props} type="button" className="slick-next">
            <FaChevronRight fill='black' size={30} className='next-arrow' />
        </button>
    );
    const settings = {
        infinite: false,
        slidesToShow: 5.5,
        slidesPerRow: 1,
        initialSlide: 0,
        // centerMode: true,
        centerMargin: "10px",
        margin: "20px", // set the time interval between slides
        // Add custom navigation buttons using Font Awesome icons
        prevArrow: <CustomPrevButton />,
        nextArrow: <CustomNextButton />
        ,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const placeHolderImage = (e) => {
        e.target.src = setting.setting?.web_logo;
    };

    return (
        <>
            <section id="products" className='mt-5'>
                <div className="container">
                    {shop.shop === null || productSizes === null
                        ? (
                            <>
                                <div className="d-flex justify-content-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </>
                        )
                        : (
                            <>
                                {shop.shop.sections.map((section, index0) => {
                                    if (section.products.length > 0) {

                                        return (

                                            <div key={index0}>

                                                <div className='product_section row flex-column' value={index0} onChange={(e) => { setOfferContainer(index0); }}>

                                                    <div className="d-flex product_title_content justify-content-between align-items-center col-md-12">
                                                        <div className="">
                                                            <p>{section.title}</p>
                                                            <span className='d-none d-md-block'>{section.short_description}</span>
                                                        </div>
                                                        {section.products?.length > 5 ?
                                                            <div>
                                                                {/* {console.log(section)} */}
                                                                <Link to="/products" onClick={() => {
                                                                    dispatch(setFilterSection({ data: section.id }));
                                                                    navigate('/products');
                                                                }}>{t('see_all')}</Link>
                                                            </div> : null}
                                                    </div>

                                                    <div className="product_section_content p-0">
                                                        <Slider className='MangoSlider' {...settings}>
                                                            {section.products.map((product, index) => {
                                                                return product?.variants?.map((variant) => (
                                                                    <div className="row" key={index}>
                                                                        <div className="col-md-12">

                                                                            <div className='product-card'>
                                                                                {/* <span className='border border-light rounded-circle p-2 px-3' id='aiEye'>
                                                                            <AiOutlineEye
                                                                                onClick={() => {
                                                                                    setselectedProduct(product);
                                                                                    setP_id(product.id); setP_V_id(product.variants[0].id);
                                                                                    setQnty(product.variants[0].cart_count + 1);
                                                                                }}
                                                                            />
                                                                        </span> */}
                                                                                <Link to={`/product/${product.slug}`} className='text-decoration-none text-reset'>

                                                                                    <div className='image-container' >
                                                                                        <img onError={placeHolderImage} src={product.image_url} alt={product.slug} className={`card-img-top`} />
                                                                                    </div>
                                                                                    <div className="card-body product-card-body p-3" >
                                                                                        {product?.rating_count > 0 ?
                                                                                            <div className='ratings d-flex align-items-center'>
                                                                                                <LuStar className='me-2' style={{ fill: "#fead0e", stroke: "#fead0e" }} />
                                                                                                <div className='border-end border-2 pe-2 me-2 avgRating'>
                                                                                                    {product?.average_rating?.toFixed(setting.setting && setting.setting.decimal_point)}
                                                                                                </div>
                                                                                                <div>
                                                                                                    {product?.rating_count}
                                                                                                </div>
                                                                                            </div> : null}
                                                                                        <h3>{product.name}</h3>
                                                                                        <div className='price'>
                                                                                            <span id={`price${index}${index0}-section`} className="d-flex align-items-center">
                                                                                                <p id='fa-rupee' className='m-0'>
                                                                                                    {setting.setting && setting.setting.currency}
                                                                                                    {variant.discounted_price === 0 ? variant.price.toFixed(setting.setting && setting.setting.decimal_point) : variant.discounted_price.toFixed(setting.setting && setting.setting.decimal_point)}
                                                                                                </p>
                                                                                            </span>
                                                                                        </div>
                                                                                        {/* <div className='product_varients_drop'>
                                                                                            <input type="hidden" name={`select-product${index}${index0}-variant-id`} id={`select-product${index}${index0}-variant-id`} value={selectedVariant.pid === product.id ? selectedVariant.id : product.variants[0].id} />
                                                                                            {product.variants.length > 1 ? <>
                                                                                                <div className='variant_selection' >
                                                                                                    <span>{<>{variant.measurement} {variant.stock_unit_name} </>}</span>
                                                                                                </div>
                                                                                            </>
                                                                                                :
                                                                                                <>
                                                                                                    <span className={`variant_value select-arrow ${product.variants[0].stock > 0 ? '' : ''}`}>{product.variants[0].measurement + " " + product.variants[0].stock_unit_name}
                                                                                                    </span>
                                                                                                </>}
                                                                                        </div> */}
                                                                                    </div>
                                                                                </Link>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ));
                                                            }
                                                            )}
                                                        </Slider>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </>
                        )}
                </div>
            </section>
        </>
    );
});

export default MangoSection;